import Vue from 'vue'
import { parsePhoneNumber } from 'libphonenumber-js'

export default Vue.extend({
  methods: {
    /**
     *
     * Send link to download app
     * @param { `+${number}` } countryCode
     * @param { string } phone
     */
    async sendDownloadLink(countryCode, phone) {
      const phoneNumber = parsePhoneNumber(`${countryCode}${phone}`)

      if (!phoneNumber.isValid())
        return Promise.reject(new Error('Please, enter a valid phone number.'))

      try {
        const { data: message } = await this.$axios.post(
          `/api/v2/sms/send-download-link/`,
          {
            phone: phoneNumber.format('E.164'),
          }
        )

        return message.status
      } catch (error) {
        return Promise.reject(
          new Error('An error has ocurred. Please try again or contact us.')
        )
      }
    },
  },
})
