<template>
  <a
    href="https://play.google.com/store/apps/details?id=com.happyly.mobile&hl=en"
    target="_blank"
    class="d-flex align-content-center"
    @click="sendEventToFP"
  >
    <v-img
      :src="googlePlaySrc"
      class="android-play"
      contain
      :style="{ 'max-width': maxwidth }"
    />
  </a>
</template>

<script>
export default {
  name: 'PlayStoreButton',
  props: {
    maxWidth: { type: String, default: '165' },
  },
  computed: {
    maxwidth() {
      return `${this.maxWidth}px`
    },
    googlePlaySrc() {
      return require('@/assets/images/google-play-badge.png')
    },
  },
  methods: {
    sendEventToFP() {
      this.$fb.track('InitiateCheckout', { button: 'Play Store' })
    },
  },
}
</script>

<style lang="scss" scoped>
.android-play {
  height: 50px;
  width: 165px;
}
</style>
