<template>
  <a
    href="https://apps.apple.com/by/app/happy-active-family/id1469853221"
    target="_blank"
    class="d-flex align-content-center"
    @click="sendEventToFP"
  >
    <v-img
      :src="staticLogoApple"
      class="app-store-img"
      :style="{
        'max-width': maxWidthImg,
      }"
      contain
    />
  </a>
</template>

<script>
export default {
  name: 'AppStoreButton',
  props: {
    maxWidth: { type: String, default: '165' },
  },
  computed: {
    maxWidthImg() {
      return `${this.maxWidth}px`
    },
    staticLogoApple() {
      return `${this.$config.staticUrl}/media/download-on-the-app-store-apple.svg`
    },
  },
  methods: {
    sendEventToFP() {
      this.$fb.track('InitiateCheckout', { button: 'App Store' })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-store-img {
  height: 50px;
  width: 165px;
}
</style>
